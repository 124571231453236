import { useAuthStore } from '~/stores/auth'
import { useUserStore } from '~/stores/user'
import { useFavoritesStore } from '~/stores/favorites'

export default async function (variant, options) {
  const {
    openModal,
    isFetch = true,
    callback = () => {},
    isInFavorites = false
  } = options
  if (!useAuthStore().loggedIn) {
    useAuthStore().showAuthDialog(useRoute().fullPath)
  } else if (isInFavorites) {
    useApi().wishlists.deleteProductFromLists(variant?.code, {
      onSuccess: () => {
        useUserStore().fetchUserData()
        callback()
      }
    })
  } else if (!isInFavorites && useUserStore().wishlists.length > 1) {
    openModal && openModal(variant)
  } else if (!isInFavorites) {
    useNuxtApp().$eSputnikEvent.addToWishlist(variant)
    await useUserStore().addProductToFavorites(
      {
        productVariantId: parseInt(variant?.id)
      },
      {
        onSuccess: callback
      }
    )
  }
  if (isFetch) {
    useFavoritesStore().fetchProductFavorites(variant?.code)
  }
}
