export const useFavoritesStore = defineStore({
  id: 'favorites-store',
  state: () => {
    return {
      list: [],
      productFavorite: {}
    }
  },
  actions: {
    fetchProductFavorites(variantCode) {
      return useApi().wishlists.getOne(variantCode, {
        onSuccess: res => {
          this.productFavorite = res
        }
      })
    },
    addToFavorites(product) {
      this.list.push(product)
    },
    removeFromFavorites(productId) {
      this.list = this.list.filter(({ id }) => productId !== id)
    }
  },
  getters: {
    favoritesList: state => state.list,
    productFavoriteData: state => state?.productFavorite
  }
})
