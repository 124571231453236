<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="close">
      <AppDialogsCommonBackdrop />

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex md:min-h-fit min-h-full justify-center text-center p-0"
        >
          <AppDialogsCommonPanelTransition>
            <DialogPanel
              class="transform rounded-0 bg-white p-5 my-0 text-left transition-all min-h-full w-full relative md:my-52 md:rounded-md md:w-[550px]"
            >
              <AppDialogsCommonCloseButton
                class="absolute right-5 top-5 z-10"
                @click="close"
              />
              <div>
                <h2 class="text-base md:text-xl font-bold mb-2.5">
                  {{ t('Вибрати список') }}
                </h2>
                <span class="text-xs md:text-sm mb-4 block"> </span>
                <form class="share-form" @submit.prevent="submit">
                  <AppFormSelect
                    id="wishlist-select"
                    v-model="selectedWishlist"
                    :options="userStore.wishlists"
                    :get-label="option => option?.name"
                    :get-value="option => option?.token"
                    class="grow"
                    :input-class="'h-11 md:rounded-r-none'"
                  />
                  <button
                    id="btn-add-to-wishlist-modal"
                    type="submit"
                    class="btn btn-primary uppercase text-xs md:rounded-l-none h-11"
                  >
                    {{ t('Вибрати') }}
                  </button>
                </form>
              </div>
            </DialogPanel>
          </AppDialogsCommonPanelTransition>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { Dialog, DialogPanel, TransitionRoot } from '@headlessui/vue'
import { useUserStore } from '~/stores/user'

const { t } = useI18n()
const props = defineProps({
  callback: {
    type: Function,
    default: () => {}
  }
})

const userStore = useUserStore()
const emits = defineEmits(['update:modelValue'])
const open = ref(false)
const variant = ref({})

const wishlists = computed(() => userStore.wishlists || [])
const selectedWishlist = ref(wishlists.value[0])

watch(wishlists, () => {
  if (wishlists.value.length) {
    selectedWishlist.value = wishlists.value[0]
  }
})

const close = () => {
  open.value = false
}

const submit = async () => {
  await userStore.addProductToFavorites(
    {
      token: selectedWishlist.value?.token,
      productVariantId: variant.value?.id
    },
    {
      onSuccess: () => props?.callback && props.callback()
    }
  )
  useNuxtApp().$eSputnikEvent.addToWishlist(variant.value)
  close()
}

onMounted(() => {
  emits('update:modelValue', {
    open: v => {
      variant.value = v
      open.value = true
    }
  })
})
</script>

<style lang="postcss" scoped>
.share-form {
  @apply flex w-full md:flex-row flex-col gap-2.5 md:gap-0;
}
</style>
